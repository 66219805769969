import { Dispatch, SetStateAction, useEffect, useState } from 'react'

type localStorageItem = {
  t: number
  v: any
}

export function useStatePersistent<Type>(
  initialValue: Type,
  localStorageKey: string, // must be unique
  ttl: number | null = 60 * 60 * 24 * 2
): [Type, Dispatch<SetStateAction<Type>>] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<Type>(() => {
    if (typeof window === 'undefined') {
      return initialValue
    }
    try {
      // Get from local storage by key
      const localStorageItem = window.localStorage.getItem(localStorageKey)
      let item = initialValue

      if (localStorageItem) {
        const contents: localStorageItem = JSON.parse(localStorageItem)

        if (contents.t === null || contents.t > new Date().getTime()) {
          item = contents.v
        }
      }

      return item
    } catch (error) {
      // If error also return initialValue
      console.error(error)
      return initialValue
    }
  })

  useEffect(() => {
    try {
      const t = ttl ? { t: new Date().getTime() + ttl } : {}

      if (typeof window !== 'undefined') {
        window.localStorage.setItem(localStorageKey, JSON.stringify({ v: storedValue, ...t }))
      }
    } catch (error) {
      console.error(error)
    }
  }, [storedValue])

  return [storedValue, setStoredValue]
}

export default useStatePersistent
