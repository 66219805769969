import { gql, useQuery } from '@apollo/client'
import { deliveryEstimateFragment } from 'components/DeliveryEstimate'
import { PlaceOrderMutation_placeOrder } from 'components/__generated__/PlaceOrderMutation'
import { skuDefinitions } from 'data/skuDefinitions'
import fp from 'lodash/fp'
import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'
import useStatePersistent from '../useStatePersistent'
import { CalculateOrderQuery, CalculateOrderQueryVariables } from './__generated__/CalculateOrderQuery'

const CALCULATE_ORDER_QUERY = gql`
  query CalculateOrderQuery(
    $orderLines: [OrderLineInput!]!
    $shippingMethodId: ID!
    $discountCode: ID
    $email: String
  ) {
    calculateOrderPrice(
      input: {
        orderLines: $orderLines
        shippingMethodId: $shippingMethodId
        discountCode: $discountCode
        email: $email
      }
    ) {
      orderLines {
        sku {
          id
          price
          description
          disabled
        }
        quantity
        unitPrice
        totalPrice
      }
      discounts {
        code
        description
        amount
        failureMessage
      }
      subTotal
      subTotalAfterDiscounts
      shippingMethodIsCustom
      shippingMethod {
        id
        description
        price
      }
      total
      ...DeliveryEstimateFragment
    }

    shippingMethods {
      id
      description
      price
    }

    partner @client
  }

  ${deliveryEstimateFragment}
`

interface BulkCartItem {
  sku: string
  qty: number | null
  fixedQuantity?: boolean
}

interface IndividualCartItem {
  sku: string
  qty: 1
  firstName: string
  lastName: string
  dob: string
  arrivalDateUk: string
  fixedQuantity?: boolean
}

export type ArrivalData = Pick<IndividualCartItem, 'firstName' | 'lastName' | 'arrivalDateUk' | 'dob'>

type CartItem = BulkCartItem | IndividualCartItem

interface Cart {
  discountCode?: string
  items: CartItem[]
  showWarning: boolean
  isNew: boolean
}

type OrderConfirmation = {
  hasResponse: boolean
  hasRefs: boolean
  orderId: string
  day2and8refLines: {
    firstName: string
    lastName: string
    day2and8ref: string
  }[]
}

interface CartContextShape {
  cart: Cart
  orderConfirmation: OrderConfirmation
  calculated: CalculateOrderQuery
  loading: boolean
  addItemToCart: (sku: string, qty: number, fixedQuantity?: boolean) => void
  setDiscountCode: (discountCode: string) => void
  changeLineQuantity: (i: number, qty: number) => void
  isEmpty: boolean
  checkoutError?: string
  orderRequest: CalculateOrderQueryVariables
  clearCart: () => void
  setArrivalData: (i: number, data: ArrivalData) => void
  removeLine: (i: number) => void
  setOrderResponse: (orderDetails: PlaceOrderMutation_placeOrder) => void
  orderResponse?: PlaceOrderMutation_placeOrder
  hasSupervisions: boolean
  onlySupervisions: boolean
}

const initialCart: Cart = { items: [], showWarning: false, isNew: true }

const CartContext = createContext<CartContextShape>({
  cart: initialCart,
  calculated: null,
  loading: false,
  addItemToCart: (sku: string, qty = 1) => {
    // no-op
  },
})

const isIndividualSku = (sku: string) => skuDefinitions[sku]?.requiresArrivalInfo || false

const getCheckoutError = (cart: Cart, data: CalculateOrderQuery) => {
  const isEmpty = cart.items.length === 0

  if (isEmpty) {
    return 'Basket is empty'
  }

  if (data?.calculateOrderPrice.orderLines.find((l) => l.sku.disabled)) {
    return 'Some items in your basket are no longer available'
  }

  const arrivalDataComplete = cart.items.every((item) => {
    if (skuDefinitions[item.sku].requiresArrivalInfo) {
      return (item as any).firstName
    }

    return true
  })

  if (!arrivalDataComplete) {
    return 'Arrival data must be filled in for all kits'
  }
}

export const CartProvider = ({ children }) => {
  const [cart, setCartInner] = useStatePersistent<Cart>(initialCart, 'shopping-basket')

  const [orderConfirmation, setOrderConfirmation] = useState<OrderConfirmation>()

  const [orderResponse, setOrderResponse] = useState<PlaceOrderMutation_placeOrder>()

  const setCart: Dispatch<SetStateAction<Cart>> = (...args) => {
    setCartInner((prev) => {
      return { ...prev, isNew: false }
    })
    setCartInner(...args)
  }

  const { data, loading } = useQuery<CalculateOrderQuery, CalculateOrderQueryVariables>(CALCULATE_ORDER_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      shippingMethodId: 'standard',
      discountCode: cart.discountCode,
      orderLines: cart.items.map((line) => ({
        skuId: line.sku,
        quantity: (line as any).qty || 1,
      })),
    },
    skip: cart.isNew,
  })

  return (
    <CartContext.Provider
      value={{
        cart,
        orderConfirmation,
        calculated: data
          ? {
              ...data,
              calculateOrderPrice: data?.calculateOrderPrice
                ? {
                    ...data.calculateOrderPrice,
                    orderLines: data.calculateOrderPrice.orderLines.map((line, i) => {
                      const item = cart.items[i]

                      if (!item) {
                        return { ...line, arrivalData: {} }
                      }

                      return {
                        ...line,
                        arrivalData: {
                          firstName: (item as IndividualCartItem).firstName,
                          lastName: (item as IndividualCartItem).lastName,
                          dob: (item as IndividualCartItem).dob,
                          arrivalDateUk: (item as IndividualCartItem).arrivalDateUk,
                          vaccinationStatus: (item as any).vaccinationStatus,
                        },
                      }
                    }),
                  }
                : null,
            }
          : null,
        loading,
        isEmpty: cart.items.length === 0,
        checkoutError: getCheckoutError(cart, data),
        clearCart: () => setCart(initialCart),
        setOrderResponse: (orderDetails: PlaceOrderMutation_placeOrder) => {
          setOrderConfirmation({
            hasResponse: !!orderDetails,
            hasRefs: !!orderDetails.orderPriceDetails.orderLines.find((x) => x.day2and8ref),
            orderId: orderDetails.orderId,
            day2and8refLines: orderDetails.orderPriceDetails.orderLines.filter((x) => x.day2and8ref),
          })

          return setOrderResponse(orderDetails)
        },
        orderResponse,
        hasSupervisions: cart.items.some((x) => ['supervision', 'supervised_rat'].includes(x.sku)),
        onlySupervisions: cart.items.every((x) => ['supervision'].includes(x.sku)),
        setDiscountCode: (discountCode: string) =>
          setCart((prev) => ({
            ...prev,
            discountCode,
          })),
        changeLineQuantity: (i: number, qty: number) => {
          setCart((prev) => fp.set(['items', i, 'qty'], qty)(prev))
        },
        addItemToCart: (sku: string, qty = 1, fixedQuantity = false) => {
          if (['gcov', 'tcov', 'rcov'].includes(sku)) {
            setCart((prev) => ({ ...prev, showWarning: true }))
            return
          }

          const isIndividual = isIndividualSku(sku)

          if (isIndividual) {
            setCart((prev) => ({
              ...prev,
              items: [
                ...prev.items,

                ...fp
                  .range(0)(qty)
                  .map(() => ({ sku, qty: 1 })),
              ],
            }))

            return
          }

          const existingIndex = cart.items.findIndex((s) => s.sku === sku)

          if (existingIndex > -1) {
            if (!fixedQuantity) {
              setCart((prev) => fp.update(['items', existingIndex, 'qty'], (q) => q + qty)(prev))
            }

            return
          }

          setCart((prev) => ({
            ...prev,
            items: [...prev.items, { sku, qty, fixedQuantity }],
          }))
        },

        removeLine: (i: number) =>
          setCart((prev) => ({
            ...prev,
            items: [...prev.items.slice(0, i), ...prev.items.slice(i + 1)],
          })),

        orderRequest: {
          shippingMethodId: 'standard',
          discountCode: cart.discountCode,
          orderLines: cart.items.map((line) => ({
            skuId: line.sku,
            quantity: (line as any).qty || 1,
            firstName: (line as IndividualCartItem).firstName,
            lastName: (line as IndividualCartItem).lastName,
            dob: (line as IndividualCartItem).dob,
            arrivalDateUk: (line as IndividualCartItem).arrivalDateUk,
            vaccinationStatus: (line as any).vaccinationStatus,
          })),
        },

        setArrivalData: (i: number, arrivalData: ArrivalData) => {
          setCart((prev) => fp.update(['items', i], (item) => ({ ...item, ...arrivalData }))(prev))
        },
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export const useCart = () => {
  const cart = useContext(CartContext)
  return cart
}
