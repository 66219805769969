import { useEffect } from 'react'
import { navigate } from 'gatsby'
import Footer from './components/Footer'
import Header from './components/Header'
// import ServiceUpdateNotification from './components/ServiceUpdateNotification'

import './pages/mystyles.scss'

const pagesWithHero = ['/fortravel', '/antigentests', '/foryourbusiness', '/recoverycertificate', '/rapidtravel']

const Layout = ({ location, children }) => {
  
  useEffect(() => {
    if (location.pathname === '/') {
      navigate('https://www.selph.co.uk')
    }
  }, [])
  
  const hasHero = location.pathname === '/' || pagesWithHero.find((path) => location.pathname.startsWith(path))

  const isDashboard = location.pathname.startsWith('/dashboard')
  // const showServiceUpdate = hasHero

  /* eslint-disable jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */
  return (
    <>
      <div id="wrapper">
        <a id="top" />
    
        {!isDashboard && <Header hasHero={hasHero} />}
        {/*        {showServiceUpdate && <ServiceUpdateNotification />} */}
        <main>{children}</main>
        {!isDashboard && <Footer />}
      </div>
    </>
  )
  /* eslint-enable jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */
}

export default Layout
