import cookies from 'js-cookie'
import ReactGA from 'react-ga4'
import ReactModal from 'react-modal'
import { trackUetPageView } from './src/helpers/bing-tracking'
import { trackFbPageView } from './src/helpers/fb-pixel'
import { initializeRollbar } from './src/helpers/rollbar'

export { wrapPageElement } from './src/wrapPageElement'
export { wrapRootElement } from './src/wrapRootElement'

export const onRouteUpdate = ({ location }) => {
  // Tracking is delayed to allow for new route to be fully rendered, not
  // sure how necessary it is but I saw it in the source for the analytics
  // plugin and it seemed safe.
  setTimeout(() => {
    const pagePath = location ? location.pathname + location.search + location.hash : undefined

    ReactGA.set({ page: pagePath })
    ReactGA.send('pageview', pagePath)

    const consentGiven = cookies.getJSON('cookieConsent')
    if (consentGiven === false) {
      // Extra safety net to ensure we're not tracking folks who have opted out
      return
    }

    trackUetPageView(pagePath)
    trackFbPageView()
  }, 50)
}

function startGa() {
  ReactGA.initialize('UA-154243968-2', {
    testMode: process.env.NODE_ENV !== 'production',
    gaOptions: {
      siteSpeedSampleRate: 100,
    },
  })

  ReactGA.set({ anonymizeIp: true })

  // We currently never update these settings and just run in
  // consent mode forever
  ReactGA.gtag('consent', 'default', {
    ad_storage: 'denied',
    analytics_storage: 'denied',
  })
}

export const onClientEntry = () => {
  startGa()

  initializeRollbar()

  ReactModal.setAppElement('#___gatsby')
}
