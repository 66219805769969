import Rollbar from 'rollbar'

let rollbarInstance: Rollbar

export const initializeRollbar = () => {
  rollbarInstance = new Rollbar({
    // Only report errors on deployed builds, not local
    enabled: !!process.env.GATSBY_NETLIFY,
    accessToken: 'e7b6ea8736b04036af411e7ab99b105b',
    captureUncaught: true,
    captureUnhandledRejections: true,
    captureIp: false,
    scrubFields: ['creditCardNumber', 'dob', 'email', 'firstName', 'lastName', 'passportNumber'],
    payload: {
      environment: process.env.NODE_ENV,
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: process.env.GATSBY_COMMIT_REF,
          guess_uncaught_frames: true,
        },
      },
    },
    hostSafeList: ['c19testing.co.uk', 'netlify.app'],
    ignoredMessages: ['NotFoundError'],
  })

  // Keep window.Rollbar for old usages when we used the snippet
  const anyWindow = window as Window & { Rollbar?: Rollbar }
  anyWindow.Rollbar = rollbarInstance
}

const rollbarWrapper = {
  log: (...args: Rollbar.LogArgument[]): Rollbar.LogResult => {
    rollbarInstance?.log(...args)
  },
  debug: (...args: Rollbar.LogArgument[]): Rollbar.LogResult => {
    rollbarInstance?.debug(...args)
  },
  info: (...args: Rollbar.LogArgument[]): Rollbar.LogResult => {
    rollbarInstance?.info(...args)
  },
  warn: (...args: Rollbar.LogArgument[]): Rollbar.LogResult => {
    rollbarInstance?.warn(...args)
  },
  warning: (...args: Rollbar.LogArgument[]): Rollbar.LogResult => {
    rollbarInstance?.warning(...args)
  },
  error: (...args: Rollbar.LogArgument[]): Rollbar.LogResult => {
    rollbarInstance?.error(...args)
  },
  critical: (...args: Rollbar.LogArgument[]): Rollbar.LogResult => {
    rollbarInstance?.critical(...args)
  },
}

export default rollbarWrapper
