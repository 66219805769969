export const skuDefinitions = {
  ncov: { price: 4900, requiresArrivalInfo: false },
  ncov_release: { price: 6400, requiresArrivalInfo: true },
  ncov_travel: { price: 9900, requiresArrivalInfo: false },
  rapid_travel: { price: 1199, requiresArrivalInfo: false },
  rapid_travel_hughes: { price: 1999, requiresArrivalInfo: false },
  rapid_event: { price: 1999, requiresArrivalInfo: false },
  day2: { price: 6400, requiresArrivalInfo: true },
  day2_rat: { price: 7900, requiresArrivalInfo: true },
  day2_lfd: { price: 1999, requiresArrivalInfo: true },
  day8: { price: 6400, requiresArrivalInfo: true },
  day2and8: { price: 12000, requiresArrivalInfo: true },
  gcov: { price: 4500, requiresArrivalInfo: false },
  tcov: { price: 4500, requiresArrivalInfo: false },
  scov: { price: 4500, requiresArrivalInfo: false },
  rcov: { price: 9000, requiresArrivalInfo: false },
  cov_antigen: {
    price: 375,
    requiresArrivalInfo: false,
    minimumQty: 4,
    incrementalPrice: 260,
  },
  supervised_rat: { price: 2999, requiresArrivalInfo: false },
  supervision: { price: 1500, requiresArrivalInfo: false },
  recoveryCert: { price: 4900, requiresArrivalInfo: false },
}

export const shippingMethodDefinitions = {
  standard: { price: 0 },
  special: {
    price: 699,
    note: 'Special delivery will ship the same day if ordered before 2pm on a weekday.',
  },
}
