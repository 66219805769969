// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-antigentests-tsx": () => import("./../../../src/pages/antigentests.tsx" /* webpackChunkName: "component---src-pages-antigentests-tsx" */),
  "component---src-pages-basket-tsx": () => import("./../../../src/pages/basket.tsx" /* webpackChunkName: "component---src-pages-basket-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-confirm-registration-tsx": () => import("./../../../src/pages/confirm-registration.tsx" /* webpackChunkName: "component---src-pages-confirm-registration-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookie-policy-mdx": () => import("./../../../src/pages/cookie-policy.mdx" /* webpackChunkName: "component---src-pages-cookie-policy-mdx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-delivery-info-tsx": () => import("./../../../src/pages/delivery-info.tsx" /* webpackChunkName: "component---src-pages-delivery-info-tsx" */),
  "component---src-pages-fortravel-tsx": () => import("./../../../src/pages/fortravel.tsx" /* webpackChunkName: "component---src-pages-fortravel-tsx" */),
  "component---src-pages-foryourbusiness-tsx": () => import("./../../../src/pages/foryourbusiness.tsx" /* webpackChunkName: "component---src-pages-foryourbusiness-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-instructions-tsx": () => import("./../../../src/pages/instructions.tsx" /* webpackChunkName: "component---src-pages-instructions-tsx" */),
  "component---src-pages-order-confirm-recovery-tsx": () => import("./../../../src/pages/order-confirm-recovery.tsx" /* webpackChunkName: "component---src-pages-order-confirm-recovery-tsx" */),
  "component---src-pages-order-confirm-tsx": () => import("./../../../src/pages/order-confirm.tsx" /* webpackChunkName: "component---src-pages-order-confirm-tsx" */),
  "component---src-pages-order-tsx": () => import("./../../../src/pages/order.tsx" /* webpackChunkName: "component---src-pages-order-tsx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../../../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-rapid-tsx": () => import("./../../../src/pages/rapid.tsx" /* webpackChunkName: "component---src-pages-rapid-tsx" */),
  "component---src-pages-rapidtravel-tsx": () => import("./../../../src/pages/rapidtravel.tsx" /* webpackChunkName: "component---src-pages-rapidtravel-tsx" */),
  "component---src-pages-recoverycertificate-tsx": () => import("./../../../src/pages/recoverycertificate.tsx" /* webpackChunkName: "component---src-pages-recoverycertificate-tsx" */),
  "component---src-pages-recoverycertvalidate-tsx": () => import("./../../../src/pages/recoverycertvalidate.tsx" /* webpackChunkName: "component---src-pages-recoverycertvalidate-tsx" */),
  "component---src-pages-register-admin-tsx": () => import("./../../../src/pages/register-admin.tsx" /* webpackChunkName: "component---src-pages-register-admin-tsx" */),
  "component---src-pages-register-confirm-day-2-and-8-tsx": () => import("./../../../src/pages/register-confirm-day2and8.tsx" /* webpackChunkName: "component---src-pages-register-confirm-day-2-and-8-tsx" */),
  "component---src-pages-register-confirm-day-2-rat-tsx": () => import("./../../../src/pages/register-confirm-day2_rat.tsx" /* webpackChunkName: "component---src-pages-register-confirm-day-2-rat-tsx" */),
  "component---src-pages-register-confirm-day-2-tsx": () => import("./../../../src/pages/register-confirm-day2.tsx" /* webpackChunkName: "component---src-pages-register-confirm-day-2-tsx" */),
  "component---src-pages-register-confirm-day-8-tsx": () => import("./../../../src/pages/register-confirm-day8.tsx" /* webpackChunkName: "component---src-pages-register-confirm-day-8-tsx" */),
  "component---src-pages-register-confirm-gcov-tsx": () => import("./../../../src/pages/register-confirm-gcov.tsx" /* webpackChunkName: "component---src-pages-register-confirm-gcov-tsx" */),
  "component---src-pages-register-confirm-ncov-release-tsx": () => import("./../../../src/pages/register-confirm-ncov_release.tsx" /* webpackChunkName: "component---src-pages-register-confirm-ncov-release-tsx" */),
  "component---src-pages-register-confirm-ncov-travel-tsx": () => import("./../../../src/pages/register-confirm-ncov_travel.tsx" /* webpackChunkName: "component---src-pages-register-confirm-ncov-travel-tsx" */),
  "component---src-pages-register-confirm-ncov-tsx": () => import("./../../../src/pages/register-confirm-ncov.tsx" /* webpackChunkName: "component---src-pages-register-confirm-ncov-tsx" */),
  "component---src-pages-register-confirm-rapid-ag-tsx": () => import("./../../../src/pages/register-confirm-rapid_ag.tsx" /* webpackChunkName: "component---src-pages-register-confirm-rapid-ag-tsx" */),
  "component---src-pages-register-confirm-rapid-multi-tsx": () => import("./../../../src/pages/register-confirm-rapid_multi.tsx" /* webpackChunkName: "component---src-pages-register-confirm-rapid-multi-tsx" */),
  "component---src-pages-register-confirm-rapid-travel-hughes-tsx": () => import("./../../../src/pages/register-confirm-rapid_travel_hughes.tsx" /* webpackChunkName: "component---src-pages-register-confirm-rapid-travel-hughes-tsx" */),
  "component---src-pages-register-confirm-rapid-travel-tsx": () => import("./../../../src/pages/register-confirm-rapid_travel.tsx" /* webpackChunkName: "component---src-pages-register-confirm-rapid-travel-tsx" */),
  "component---src-pages-register-confirm-salient-pcr-tsx": () => import("./../../../src/pages/register-confirm-salient_pcr.tsx" /* webpackChunkName: "component---src-pages-register-confirm-salient-pcr-tsx" */),
  "component---src-pages-register-confirm-scov-tsx": () => import("./../../../src/pages/register-confirm-scov.tsx" /* webpackChunkName: "component---src-pages-register-confirm-scov-tsx" */),
  "component---src-pages-register-confirm-tcov-tsx": () => import("./../../../src/pages/register-confirm-tcov.tsx" /* webpackChunkName: "component---src-pages-register-confirm-tcov-tsx" */),
  "component---src-pages-register-confirm-tdl-pcr-tsx": () => import("./../../../src/pages/register-confirm-tdl_pcr.tsx" /* webpackChunkName: "component---src-pages-register-confirm-tdl-pcr-tsx" */),
  "component---src-pages-register-confirm-undefined-tsx": () => import("./../../../src/pages/register-confirm-undefined.tsx" /* webpackChunkName: "component---src-pages-register-confirm-undefined-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-registration-5-west-tsx": () => import("./../../../src/pages/registration/5west.tsx" /* webpackChunkName: "component---src-pages-registration-5-west-tsx" */),
  "component---src-pages-registration-alpla-tsx": () => import("./../../../src/pages/registration/alpla.tsx" /* webpackChunkName: "component---src-pages-registration-alpla-tsx" */),
  "component---src-pages-registration-at-medics-tsx": () => import("./../../../src/pages/registration/at-medics.tsx" /* webpackChunkName: "component---src-pages-registration-at-medics-tsx" */),
  "component---src-pages-registration-billys-tsx": () => import("./../../../src/pages/registration/billys.tsx" /* webpackChunkName: "component---src-pages-registration-billys-tsx" */),
  "component---src-pages-registration-doubleband-tsx": () => import("./../../../src/pages/registration/doubleband.tsx" /* webpackChunkName: "component---src-pages-registration-doubleband-tsx" */),
  "component---src-pages-registration-fosse-tsx": () => import("./../../../src/pages/registration/fosse.tsx" /* webpackChunkName: "component---src-pages-registration-fosse-tsx" */),
  "component---src-pages-registration-glenbrae-tsx": () => import("./../../../src/pages/registration/glenbrae.tsx" /* webpackChunkName: "component---src-pages-registration-glenbrae-tsx" */),
  "component---src-pages-registration-haworth-tsx": () => import("./../../../src/pages/registration/haworth.tsx" /* webpackChunkName: "component---src-pages-registration-haworth-tsx" */),
  "component---src-pages-registration-hippo-tsx": () => import("./../../../src/pages/registration/hippo.tsx" /* webpackChunkName: "component---src-pages-registration-hippo-tsx" */),
  "component---src-pages-registration-kds-tsx": () => import("./../../../src/pages/registration/kds.tsx" /* webpackChunkName: "component---src-pages-registration-kds-tsx" */),
  "component---src-pages-registration-ltsadvisory-tsx": () => import("./../../../src/pages/registration/ltsadvisory.tsx" /* webpackChunkName: "component---src-pages-registration-ltsadvisory-tsx" */),
  "component---src-pages-registration-marpal-tsx": () => import("./../../../src/pages/registration/marpal.tsx" /* webpackChunkName: "component---src-pages-registration-marpal-tsx" */),
  "component---src-pages-registration-nexus-tsx": () => import("./../../../src/pages/registration/nexus.tsx" /* webpackChunkName: "component---src-pages-registration-nexus-tsx" */),
  "component---src-pages-registration-pda-tsx": () => import("./../../../src/pages/registration/pda.tsx" /* webpackChunkName: "component---src-pages-registration-pda-tsx" */),
  "component---src-pages-registration-pi-tsx": () => import("./../../../src/pages/registration/pi.tsx" /* webpackChunkName: "component---src-pages-registration-pi-tsx" */),
  "component---src-pages-registration-progressive-tsx": () => import("./../../../src/pages/registration/progressive.tsx" /* webpackChunkName: "component---src-pages-registration-progressive-tsx" */),
  "component---src-pages-registration-prolighting-tsx": () => import("./../../../src/pages/registration/prolighting.tsx" /* webpackChunkName: "component---src-pages-registration-prolighting-tsx" */),
  "component---src-pages-registration-ralph-tsx": () => import("./../../../src/pages/registration/ralph.tsx" /* webpackChunkName: "component---src-pages-registration-ralph-tsx" */),
  "component---src-pages-registration-recycling-tsx": () => import("./../../../src/pages/registration/recycling.tsx" /* webpackChunkName: "component---src-pages-registration-recycling-tsx" */),
  "component---src-pages-registration-salvo-tsx": () => import("./../../../src/pages/registration/salvo.tsx" /* webpackChunkName: "component---src-pages-registration-salvo-tsx" */),
  "component---src-pages-registration-spinks-tsx": () => import("./../../../src/pages/registration/spinks.tsx" /* webpackChunkName: "component---src-pages-registration-spinks-tsx" */),
  "component---src-pages-registration-stokes-tsx": () => import("./../../../src/pages/registration/stokes.tsx" /* webpackChunkName: "component---src-pages-registration-stokes-tsx" */),
  "component---src-pages-registration-sulzer-tsx": () => import("./../../../src/pages/registration/sulzer.tsx" /* webpackChunkName: "component---src-pages-registration-sulzer-tsx" */),
  "component---src-pages-registration-tt-2-tsx": () => import("./../../../src/pages/registration/tt2.tsx" /* webpackChunkName: "component---src-pages-registration-tt-2-tsx" */),
  "component---src-pages-registration-verder-tsx": () => import("./../../../src/pages/registration/verder.tsx" /* webpackChunkName: "component---src-pages-registration-verder-tsx" */),
  "component---src-pages-registration-walter-tsx": () => import("./../../../src/pages/registration/walter.tsx" /* webpackChunkName: "component---src-pages-registration-walter-tsx" */),
  "component---src-pages-registration-wartsila-tsx": () => import("./../../../src/pages/registration/wartsila.tsx" /* webpackChunkName: "component---src-pages-registration-wartsila-tsx" */),
  "component---src-pages-registration-white-tsx": () => import("./../../../src/pages/registration/white.tsx" /* webpackChunkName: "component---src-pages-registration-white-tsx" */),
  "component---src-pages-result-tsx": () => import("./../../../src/pages/result.tsx" /* webpackChunkName: "component---src-pages-result-tsx" */),
  "component---src-pages-rip-c-19-testing-tsx": () => import("./../../../src/pages/rip-c19testing.tsx" /* webpackChunkName: "component---src-pages-rip-c-19-testing-tsx" */),
  "component---src-pages-salient-tsx": () => import("./../../../src/pages/salient.tsx" /* webpackChunkName: "component---src-pages-salient-tsx" */),
  "component---src-pages-serviceupdate-tsx": () => import("./../../../src/pages/serviceupdate.tsx" /* webpackChunkName: "component---src-pages-serviceupdate-tsx" */),
  "component---src-pages-tdl-tsx": () => import("./../../../src/pages/tdl.tsx" /* webpackChunkName: "component---src-pages-tdl-tsx" */),
  "component---src-pages-terms-of-service-mdx": () => import("./../../../src/pages/terms-of-service.mdx" /* webpackChunkName: "component---src-pages-terms-of-service-mdx" */),
  "component---src-pages-video-supervision-tsx": () => import("./../../../src/pages/video-supervision.tsx" /* webpackChunkName: "component---src-pages-video-supervision-tsx" */),
  "component---src-pages-view-recovery-certificate-tsx": () => import("./../../../src/pages/view-recovery-certificate.tsx" /* webpackChunkName: "component---src-pages-view-recovery-certificate-tsx" */),
  "component---src-pages-whoweare-tsx": () => import("./../../../src/pages/whoweare.tsx" /* webpackChunkName: "component---src-pages-whoweare-tsx" */)
}

