import { ApolloProvider } from '@apollo/client'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import { CartProvider } from 'components/Cart/CartContext'
import { client } from './client'
import font from './fonts/fira-sans-regular.woff2'

let stripePromise: Promise<Stripe>

if (typeof window !== undefined) {
  stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY)
} else {
  stripePromise = Promise.reject(new Error("Can't load stripe server side"))
}

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            src: `url(${font})`,
            family: 'Fira Sans',
            weight: '400',
          },
        ],
      }}
    >
      <CartProvider>{element}</CartProvider>
    </Elements>
  </ApolloProvider>
)
