module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MHBS3SF","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"C19 Testing","titleTemplate":"%s | C19 Testing","description":"Coronavirus test kits and simple, clear advice on testing for consumers and businesses during the COVID-19 pandemic","openGraph":{"type":"website","locale":"en_GB","url":"https://www.c19testing.co.uk","site_name":"C19 Testing","images":[{"url":"https://www.c19testing.co.uk/logo.png","width":301,"height":185,"alt":"C19 Testing Logo"}]}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/opt/build/repo/src/components/mdxLayout.tsx"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-embed-video","options":{"ratio":1.77,"related":false,"noIframeBorder":true,"loadingStrategy":"lazy","urlOverrides":[{"id":"youtube"}],"containerClass":"embedVideo-container","iframeId":false}},"gatsby-remark-responsive-iframe",{"resolve":"gatsby-remark-external-links","options":{}},{"resolve":"gatsby-remark-autolink-headers","options":{"icon":false}},{"resolve":"gatsby-remark-smartypants"},"gatsby-remark-relative-images",{"resolve":"gatsby-remark-images","options":{"maxWidth":576,"wrapperStyle":"margin: 2rem auto;"}}],"remarkPlugins":[null],"extensions":[".mdx"],"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"C19 Testing","short_name":"C19 Testing","icon":"src/images/favicon.svg","start_url":"/","background_color":"#ffffff","theme_color":"#c116ef","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1d5c4158c42c2e0a67564e2828c685b0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
