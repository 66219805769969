import { gql } from '@apollo/client'
import { enGB } from 'date-fns/esm/locale'
import {
  differenceInHours,
  formatDurationWithOptions,
  formatRelativeWithOptions,
  intervalToDuration,
  parseISO,
} from 'date-fns/fp'
import { Link } from 'gatsby'
import useInterval from 'helpers/useInterval'
import { useState } from 'react'
import shippingIcon from '../images/shipping-icon.svg'
import { DeliveryEstimateFragment_deliveryEstimate } from './__generated__/DeliveryEstimateFragment'

export const deliveryEstimateFragment = gql`
  fragment DeliveryEstimateFragment on OrderPriceDetails {
    deliveryEstimate {
      dispatchDate
      arrivalDate
      expiresAt
    }
  }
`

const formatRelativeLocale = {
  lastWeek: "'last' eeee",
  yesterday: "'yesterday",
  today: "'today",
  tomorrow: "'tomorrow",
  nextWeek: "'on' eeee",
  other: "'on' P",
}

const humanizeDay = (date: Date, timeNow: Date) => {
  const relative = formatRelativeWithOptions({
    locale: {
      ...enGB,
      formatRelative: (token) => formatRelativeLocale[token],
    },
  })(timeNow, date)

  const hasOn = relative.startsWith('on ')

  return (
    <>
      {hasOn && 'on '}
      <span className="has-text-weight-normal">{hasOn ? relative.slice(3) : relative}</span>
    </>
  )
}

const formatDuration = formatDurationWithOptions({
  format: ['hours', 'minutes'],
})

const formatRelativeLocaleForCutoff = {
  lastWeek: "'last' eeee 'at' p",
  yesterday: "'yesterday at' p",
  today: "'today at' p",
  tomorrow: "'tomorrow at' ha",
  nextWeek: "eeee 'at' ha",
  other: 'P',
}

const humanizeDuration = (start: Date, end: Date) => {
  if (differenceInHours(start, end) < 12) {
    const duration = intervalToDuration({
      start,
      end,
    })

    return (
      <>
        in the next <span className="has-text-weight-normal">{formatDuration(duration)}</span>
      </>
    )
  }

  const relative = formatRelativeWithOptions({
    locale: {
      ...enGB,
      formatRelative: (token) => formatRelativeLocaleForCutoff[token],
    },
  })(start, end)

  return (
    <>
      by <span className="has-text-weight-normal">{relative}</span>
    </>
  )
}

const DeliveryEstimate = ({ deliveryEstimate }: { deliveryEstimate: DeliveryEstimateFragment_deliveryEstimate }) => {
  const [timeNow, setTimeNow] = useState(new Date())

  useInterval(() => setTimeNow(new Date()), 10000)

  const expiresAt = parseISO(deliveryEstimate.expiresAt)
  const dispatchDate = parseISO(deliveryEstimate.dispatchDate)
  const arrivalDate = parseISO(deliveryEstimate.arrivalDate)

  return (
    <div className="is-flex is-align-items-center has-text-weight-light">
      <img src={shippingIcon} width="20px" alt="shipping icon" />
      <div className="ml-2">
        <p className="is-size-7-mobile">
          Order {humanizeDuration(timeNow, expiresAt)} for dispatch {humanizeDay(dispatchDate, timeNow)}
          {', '}
          and delivery {humanizeDay(arrivalDate, timeNow)} (
          <Link to="/delivery-info">except for Northern Ireland and the Scottish Highlands & Islands</Link>
          ).
        </p>
      </div>
    </div>
  )
}

export default DeliveryEstimate
