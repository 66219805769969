import Navbar from './Navbar'

const Header = ({ hasHero }) => {

  return (
    <header>
      {!hasHero && <Navbar hasHero={hasHero} />}
    </header>
  )
}

export default Header
