import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { relayStylePagination } from '@apollo/client/utilities'
import { Auth } from '@aws-amplify/auth'
import fetch from 'isomorphic-fetch'
import rollbar from './helpers/rollbar'

// const isBuildTime = typeof window === 'undefined'

const httpLink = createHttpLink({
  uri: '/graphql',
  fetch,
})

const authLink = setContext(async (_, { headers }) => {
  let currentSession

  try {
    currentSession = await Auth.currentSession()
  } catch (err) {
    return { headers }
  }

  const token = currentSession.getIdToken().getJwtToken()

  return {
    headers: {
      ...headers,
      Authorization: token,
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) graphQLErrors.forEach((graphQLError) => rollbar.error('Apollo Client GraphQL error', graphQLError))

  if (networkError) rollbar.error('Apollo Client network error', networkError)
})

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          partner: {
            read(partner) {
              return partner || null
            },
          },
          unmatchedResultsConnection: relayStylePagination(),
        },
      },
      DiscountCode: {
        keyFields: ['code'],
      },
      Kit: {
        fields: {
          registrationInfo: {
            merge: true,
          },
        },
      },
      Result: {
        fields: {
          isHandled: {
            read(isHandled) {
              return isHandled || false
            },
          },
        },
      },
      Certificate: {
        keyFields: ['kitId'],
        merge: true,
      },
    },
  }),
})
